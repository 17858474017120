var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.allowViewConfig
    ? _c("pre", [_c("code", [_vm._v(_vm._s(_vm.yamlConfig))])])
    : _c("AccessError")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }